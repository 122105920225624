import {graphql} from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import queryUtils from 'utils/queryUtils'
import {MSEC_1_HOUR, MSEC_20_MINUTES, MSEC_10_MINUTES, MSEC_10_SECONDS} from 'constants/constants'


const EventsQuery = gql`
  query EventsQuery {
    events {
      id
      order
      name
      type
      description
      imageUrl
      startsAt
      endsAt
      isPublished
      isCompetitionMode
      competitionRules
      isAdmin
      isTester
      isStudent
      isInfo
      visibilityType
      isRegistrationAllowed
      isVisibleWithoutRegistration
      license {
          id
          number
          priority
          organizerName
      }
      eventUserInfo {
        id
        competitionSessionStartedAt
      }
    }
  }
`;


export const eventFields =`
      id
      name
      type
      description
      imageUrl
      startsAt
      endsAt
      welcomeTitle
      welcomeMessage
      isPublished
      isUserTeams
      isCompetitionMode
      competitionRules
      competitionModeInfoMessage
      isAdmin
      isTester
      isStudent
      isInfo
      visibilityType
      isRegistrationAllowed
      isVisibleWithoutRegistration
      isTournamentTableVisible
      isOnti
      accessError
      linkUuid
      scenarios {
        id
        parentId
        order
        name
        type
        imageUrl
        descriptionShort
        startsAt
        endsAt
        hasUserSolution
        hasTeamSolution
      }`;

const EventQuery = gql`
  query EventQuery($id: ID!) {
    event(id: $id) {
        ${eventFields}
    }
  }
`;


const EventUserInfoQuery = gql`
  query EventUserInfoQuery($eventId: ID!, $asRole: String) {
    eventUserInfo(eventId: $eventId, asRole: $asRole) {
      id
      isWelcomeSeen
      competitionSessionStartedAt
    }
  }
`;


const UserTeamQuery = gql `
  query UserTeamQuery($eventId: ID!) {
    userTeam(eventId: $eventId) {
      id
      name
    }
  }
`;


const EventTeamsWithStudentsQuery = gql`
  query EventTeamsWithStudentsQuery($eventId: ID!) {
    eventTeamsWithStudents(eventId: $eventId) {
      studentTeam {
        id
        name
      }
      studentsWithUsers {
        student {
          id
          email
        }
        user {
          id
          name
        }
      }
      captainStudent {
        id
      }
    }
  }
`;


const scenarioFields =`
      id
      eventId
      parentId
      order
      name
      type
      imageUrl
      description
      descriptionShort
      descriptionParameters
      descriptionScore
      startsAt
      endsAt
      prototypeParameters
      prototypeResults
      isEvaluation
      parameters
      maxSolutionsNumber
      show3dResults
      scenarioRequirements {
        id
        minimumScore
      }
      scenarioUserCustomizationStatus
      scenarioUserCustomizationError`;

const ScenarioQuery = gql`
  query ScenarioQuery($id: ID!, $asRole: String) {
    scenario(id: $id, asRole: $asRole) {
      ${scenarioFields}
    }
  }
`;


export const SolutionsQuery = gql`
  query SolutionsQuery($scenarioId: ID!, $asRole: String) {
    solutions(scenarioId: $scenarioId, asRole: $asRole) {
      id
      createdAt
      modifiedAt
      submittedAt
      description
      status
      parameters
      number
      results
      score
      resultNumber
      user {
        id
        name
      }
      simulation {
        id
        status
        resultTotal
      }
    }
  }
`;


export const solutionFields = `
      id
      scenarioId
      createdAt
      modifiedAt
      submittedAt
      description
      status
      parameters
      number
      results
      score
      resultNumber
      simulation {
        id
        status
        taskError
        resultTotal
        simulationData
      }
      user {
        id
        name
      }`;

const SolutionQuery = gql`
  query SolutionQuery($id: ID!, $asRole: String) {
    solution(id: $id, asRole: $asRole) {
      ${solutionFields}
    }
  }
`;


const ResultsQuery = gql`
  query ResultsQuery($solutionId: ID!, $asRole: String) {
    results(solutionId: $solutionId, asRole: $asRole)
  }
`;


export const EventResultsQuery = gql`
  query EventResultsQuery($eventId: ID!) {
    eventResults(eventId: $eventId) {
      user {
        id
        email
        name
      }
      uniqueUserName
      studentTeam {
        id
        name
      }
      scenario {
        id
        name
        order
        isIndividual
      }
      score
      studentScoreCorrections {
        id
        score
        achievement
      }
      teamScoreCorrections {
        id
        score
        achievement
      }
    }
  }
`;


export const DevicesQuery = gql`
  query DevicesQuery($devicesIds: [ID!]) {
    devices(devicesIds: $devicesIds) {
      id
      name
      description
      createdAt
      modifiedAt
      parameters
    }
  }
`;


export const PylintQuery = gql`
  query PylintQuery($text: String!, $iteration: Int!) {
    pylint(text: $text, iteration: $iteration) {
      iteration
      errors {
        lineNo
        message
        severity
      }
    }
  }
`;



export const queryEvents =
    graphql(EventsQuery, {
        options: {
            fetchPolicy: 'cache-first',
            pollInterval: MSEC_1_HOUR
        },
        // skip: ({isAuthorized}) => !isAuthorized,
        props: ({ ownProps, data }) => {
            return {
                loading: queryUtils.mergeWith(ownProps.loading, {events: data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {events: data.error && data.error.message}),
                events: data.events
            }
        }
    });


export const queryEvent =
    graphql(EventQuery, {
        options: ({eventId}) => {
            return {
                variables: { id: eventId },
                fetchPolicy: 'cache-first',
                pollInterval: MSEC_1_HOUR
            };
        },
        skip: ({eventId}) => !eventId,
        props: ({ ownProps, data }) => {
            return {
                loading: queryUtils.mergeWith(ownProps.loading, {event: data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {event: data.error && data.error.message}),
                event: data.event
            }
        },
    });


export const queryEventUserInfo = graphql(EventUserInfoQuery, {
    options: ({eventId, queryAsRole}) => ({
        variables: { eventId: eventId, asRole: queryAsRole },
        // this is changed by user, no server changes
        fetchPolicy: 'cache-first'
    }),
    skip: ({eventId}) => !eventId,
    props: ({ ownProps, data }) => {
        return {
            loading: queryUtils.mergeWith(ownProps.loading, {eventUserInfo: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {eventUserInfo: data.error && data.error.message}),
            eventUserInfo: data.eventUserInfo
        }
    },
});


export const queryUserTeam =
    graphql(UserTeamQuery, {
        options: ({eventId}) => ({
            variables: { eventId: eventId },
            fetchPolicy: 'cache-first',
            pollInterval: MSEC_10_MINUTES
        }),
        skip: ({eventId}) => !eventId,
        props: ({ ownProps, data }) => {
            return {
                loading: queryUtils.mergeWith(ownProps.loading, {userTeam: data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {userTeam: data.error && data.error.message}),
                userTeam: data.userTeam
            }
        },
    });


export const queryEventTeamsWithStudents =
    graphql(EventTeamsWithStudentsQuery, {
        options: ({eventId}) => ({
            variables: { eventId: eventId },
            fetchPolicy: 'cache-first',
            pollInterval: MSEC_10_MINUTES
        }),
        skip: ({eventId}) => !eventId,
        props: ({ ownProps, data }) => {
            return {
                loading: queryUtils.mergeWith(ownProps.loading, {eventTeamsWithStudents: data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {eventTeamsWithStudents: data.error && data.error.message}),
                eventTeamsWithStudents: data.eventTeamsWithStudents
            }
        },
    });


export const queryScenario =
    graphql(ScenarioQuery, {
        options: ({scenarioId, queryAsRole}) => {
            return {
                variables: { id: scenarioId, asRole: queryAsRole },
                fetchPolicy: 'cache-first',
                pollInterval: MSEC_20_MINUTES
            };
        },
        skip: ({scenarioId}) => !scenarioId,
        props: ({ ownProps, data }) => {
            return {
                loading: queryUtils.mergeWith(ownProps.loading, {scenario: data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {scenario: data.error && data.error.message}),
                scenario: data.scenario,
                // optional, because refetch does not exists everywhere
                refetch: queryUtils.mergeWith(ownProps.refetch, {scenario: data.refetch}, true)
            }
        },
    });


export const querySolutions =
    graphql(SolutionsQuery, {
        options: ({scenarioId, queryAsRole}) => ({
            variables: { scenarioId: scenarioId, asRole: queryAsRole },
            fetchPolicy: 'cache-first',
            pollInterval: MSEC_10_SECONDS
        }),
        // skips if solutions already present - higher up is the same component
        skip: ({solutions, scenarioId}) => solutions || !scenarioId,
        props: ({ ownProps, data }) => {
            return {
                loading: queryUtils.mergeWith(ownProps.loading, {solutions: data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {solutions: data.error && data.error.message}),
                solutions: data.solutions
            }
        }
    });


export const querySolution =
    graphql(SolutionQuery, {
        options: ({solutionId, queryAsRole}) => {
            return {
                variables: { id: solutionId, asRole: queryAsRole },
                fetchPolicy: 'cache-and-network',
                pollInterval: queryAsRole === "admin" ? MSEC_10_SECONDS : undefined
            };
        },
        skip: ({solutionId}) => !solutionId,
        props: ({ ownProps, data }) => {
            return {
                loading: queryUtils.mergeWith(ownProps.loading, {solution: data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {solution: queryUtils.joinErrors(ownProps.errors.solution, data.error && data.error.message)}),
                solution: data.solution
            }
        },
    });


// export const pollSolutions =
//     graphql(SolutionsQuery, {
//         options: ({scenarioId, queryAsRole}) => ({
//             variables: { scenarioId: scenarioId, asRole: queryAsRole },
//             pollInterval: 10000
//         }),
//         skip: ({loading, scenarioId}) => (loading && loading.solutionsRawData !== undefined) || !scenarioId,
//         props: ({ ownProps, data }) => ({
//             loading: queryUtils.mergeWith(ownProps.loading, {solutionsRawData: data.loading}),
//             // errors: queryUtils.mergeWith(ownProps.errors, {solutionsRawData: queryUtils.joinErrors(ownProps.errors.solutionsRawData, data.error && data.error.message)}),
//             solutionsRawData: data.solutions
//         })
//     });


export const queryResultsData =
    graphql(ResultsQuery, {
        options: ({solution, queryAsRole}) => {
            return ({
                variables: {solutionId: solution.id, asRole: queryAsRole},
                fetchPolicy: 'no-cache'
            })
        },
        skip: ({solution}) => {
            return !solution || !['finished', 'evaluating'].includes(solution.status) || !solution.simulation || !solution.simulation.id;
        },
        props: ({ownProps, data}) => {
            return ({
                loading: queryUtils.mergeWith(ownProps.loading, {resultsData: data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {resultsData: data.error && data.error.message}),
                resultsData: data.results
            })
        }
    });


export const queryEventResults =
    graphql(EventResultsQuery, {
        options: ({eventId}) => {
            return {
                variables: { eventId: eventId },
                fetchPolicy: 'cache-first',
                pollInterval: MSEC_10_MINUTES
            };
        },
        skip: ({eventId}) => !eventId,
        props: ({ ownProps, data }) => {
            return {
                loading: queryUtils.mergeWith(ownProps.loading, {eventResults: ownProps.loading.solution || data.loading}),
                errors: queryUtils.mergeWith(ownProps.errors, {eventResults: queryUtils.joinErrors(ownProps.errors.eventResults, data.error && data.error.message)}),
                eventResults: data.eventResults
            }
        }
    });


export const queryDevices =
    graphql(DevicesQuery, {
        options: ({devicesIds}) => ({
            variables: { devicesIds: devicesIds },
            fetchPolicy: 'cache-first',
            pollInterval: MSEC_1_HOUR
        }),
        skip: ({devicesIds}) => !devicesIds || devicesIds.length === 0,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {devices: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {devices: data.error && data.error.message}),
            devices: data.devices
        })
    });