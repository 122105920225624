// backend message: 'this is a {key1} string and this is {key2|item:value} of json {"a": 12, "b": 5}'
// if translation is not found, the {key} is taken as it is. Result will be:
//                  'this is a translated string and this is example value of json {"a": 12, "b": 5}'
//                             ^^^--- key1                   ^^^--- key2 with substitution ^^^--- no translation found
export const parseBackendMessage = (backendMessage, t) => {
    const regex = /{([^{}]+)}/g;

    const parsedMessage = (backendMessage || '').replace(regex, (match, rawGroup) => {
        let group = rawGroup;
        let keyValuePairs;
        if (group.includes('|')) {
            const [parsedGroup, ...rest] = group.split('|');
            const keyValueString = rest.join('|');
            keyValuePairs = keyValueString.split(',').reduce((acc, pair) => {
                const [key, value] = pair.split(':');
                acc[key] = value;
                return acc;
            }, {});
            group = parsedGroup;
        }
        const tgroup = t(group, keyValuePairs);
        return tgroup !== group ? tgroup : '{' + group + '}';
    });
    return parsedMessage;
};

// if value contains #key# it would be replaced with translation
export const translateObjOrArray = (obj, t) => {
    if ([undefined, null].includes(obj)) {
        return obj;
    }
    const stringifiedObj = JSON.stringify(obj);
    return JSON.parse(stringifiedObj.replace(/#[a-zA-Z0-9_.]+#/g, key => t(key.slice(1, -1))));
};

export const createMlJSONStringWithCurrentLanguage = (string, i18n) => {
  return JSON.stringify({[i18n.resolvedLanguage]: string});
};


const translationUtils = {
    parseBackendMessage,
    translateObjOrArray,
    createMlJSONStringWithCurrentLanguage
};

export default translationUtils;