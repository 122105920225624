import React, {useState, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import {Alert} from 'reactstrap'
import useDebug from 'hooks/useDebug'
import useSmartNavigate from 'hooks/useSmartNavigate'

import {breadcrumbsStore} from 'appBase/TopNav'

import withUriParams from "hocs/withUriParams"
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import PanelModal from 'components/modals/PanelModal'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'

import {queryRolesWithUsers} from '../data/serviceQueries'
import {addRoleUser, deleteRoleUser} from '../data/serviceMutations'

import {parseBackendMessage} from 'utils/translationUtils'
import {SERVICE_ADMIN_VIEW, EMAIL_REGEX_STRING} from 'constants/constants'

const ADMIN_ROLE_ID = 1;

const AdminView = compose(
     withUriParams([['roleId', Number]]),
     queryRolesWithUsers,
     addRoleUser,
     deleteRoleUser,
     withGeneratorDataSource,
     withInitialDataLoadWaiting(['rolesWithUsers'])
)((props) => {
    useDebug("AdminView", props);
    const {roleId, addRoleUser, deleteRoleUser, rolesWithUsers,
           dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate} = props;

    const [error, setError] = useState(null);
    const navigate = useSmartNavigate();
    const {t} = useTranslation();

    // preparing links
    breadcrumbsStore.register(SERVICE_ADMIN_VIEW);
    useEffect(() => {
        breadcrumbsStore.set(SERVICE_ADMIN_VIEW, {name: t('appService.users'), to: `${props.uri}`}, true);
    });

    // stable empty object for dataSource
    const roleData = useMemo(() => ({}), []);
     // setting dataSource
    useEffect(() => {
      setDataSource(
          roleData,
          (data) => {
              data = {roleId: roleId, email: data.email};
              addRoleUser(data)
                  .then(({data: {addRoleUser: {id, error}}}) => {
                      setError(error ? parseBackendMessage(error, t): null);
                      navigate(`${props.uri}${props.location.search}`)
                  });
          });
    }, [roleData, roleId, dataSource, setDataSource, props.uri, props.location.search, addRoleUser, t]);


    //role blocks
    const roleBlocks = [];
    rolesWithUsers.forEach((r, i) => {
        const users = r.users && r.users.map((u, i) => ({
            fields: [u.name, u.email],
            actions: (r.id !== ADMIN_ROLE_ID) && {
                "removeConfirmation":  () =>
                    deleteRoleUser({roleId: r.id, userId: u.id})
                        .then(({data: {deleteRoleUser: {id, error}}}) => {
                            setError(error ? parseBackendMessage(error, t): null);
                        })
            }
        }));

        r.id !== ADMIN_ROLE_ID && users.push({
            fields: [t('appService.views.AdminView.addUser')],
            className: "text-end",
            notSelectable: true,
            actions: {
                "click": () => navigate(`${props.uri}/${r.id}${props.location.search}`)
            }
        });

        roleBlocks.push(
            <NamedBlock name={r.name} key={i}>
                <ListWithSort headers={[t('appService.views.AdminView.name'), t('appService.views.AdminView.email')]} sizes={["100*", "200*"]} rows={users} />
            </NamedBlock>
        );
    });

    const onCancelEdit = () => {
        setDataSource(null);
        navigate(`${props.uri}${props.location.search}`);
    };

    //rendering
    return (
        <div className="d-flex flex-column h-100 w-100">
            <div className="flex-grow d-flex flex-column scroll-parent">
                {error &&
                <Alert className="mb-0" color="danger">{error}</Alert>}

                <div className="flex-grow d-flex scroll-parent">
                    <div className="flex-grow scroll">
                        <h5 className="text-center mt-3">{t('appService.users')}</h5>
                        <div className="container">

                            {roleBlocks}

                        </div>
                    </div>
                </div>

                {roleId &&
                <PanelModal show title={t('appService.views.AdminView.addUser2')} onClose={onCancelEdit} noPadding scrollable>
                    <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors} onSave={saveAndReset} onCancel={onCancelEdit} />
                    <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                        <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                                        items={[{
                                            name: t('appService.views.AdminView.email'),
                                            type: "string",
                                            field: "email",
                                            pattern: {
                                                pattern: EMAIL_REGEX_STRING,
                                                error: t('appService.views.AdminView.invalidEmail'),
                                            },
                                            required: true
                                        }]} />
                    </div>
                </PanelModal>}
            </div>
        </div>
    );
});

export default AdminView;