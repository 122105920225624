// OBJECT TYPES
export const SPACECRAFT = "spacecraft";
export const STATION = "station";
export const EVENT = "event";
export const SCENARIO = "scenario";
export const SOLUTION = "solution";
export const SIMULATION = "simulation";
export const DEVICE = "device";
export const CATALOG = "catalog";
export const CATALOG_ENTRY = "catalogEntry";
export const CATALOG_ENTRY_VERSION = "catalogEntryVersion";
export const STUDENT = "student";
export const STUDENT_GROUP = "studentGroup";
export const STUDENT_GROUP_ASSIGNMENT = "studentGroupAssignment";
export const STUDENT_TEAM = "studentTeam";
export const STUDENT_TEAM_ASSIGNMENT = "studentTeamAssignment";
export const IMAGE = "image";

// PROTO OBJECTS
export const RESULTS = "results";

// OBJECT ARRAYS
export const SPACECRAFTS = "spacecrafts";
export const ORBITS = "orbits";
export const STATIONS = "stations";

// VIEWS
export const COMPOSE_SPACECRAFT_VIEW = "composeSpacecrafts";
export const RESULTS_VIEW = "results";
export const CESIUM_3D_VIEW = "cesium3d";
export const AUTHORIZATION_VIEW = "authorization";
export const TOURNAMENT_TABLE_VIEW = "tournamentTable";
export const EVENTS_VIEW = "events";
export const EVENT_VIEW = "event";
export const SCENARIO_VIEW = "scenario";
export const SOLUTIONS_VIEW = "solutions";
export const PROFILE_VIEW = "profile";
export const TEAM_VIEW = "team";
export const EVENT_WELCOME_VIEW = "eventWelcome";
export const CATALOG_VIEW = "catalog";
export const ADMIN_STUDENTS_VIEW = "adminStudents";
export const ADMIN_EVENTS_VIEW = "adminEvents";
export const ADMIN_EVENT_VIEW = "adminEvent";
export const ADMIN_SCENARIO_VIEW = "adminScenario";
export const ADMIN_IMAGES_VIEW = "adminImages";
export const ADMIN_SOLUTIONS_VIEW = "adminSolutions";
export const ADMIN_USERS_PROGRESS_VIEW = "adminUsersProgress";
export const ADMIN_CATALOG_VIEW = "adminCatalog";

export const SERVICE_ADMIN_VIEW = "serviceAdmin";
export const SERVICE_EMBED_VIEW = "serviceEmbed";
export const SERVICE_TEMPLATES_VIEW = "serviceTemplates";
export const SERVICE_DEVICES_VIEW = "serviceDevices";
export const SERVICE_REGISTRATION_VIEW = "serviceRegistration";
export const LICENSES_VIEW = "licenses";
export const LOGOUT = "logout";
export const BACK = "back";

// FIELDS
export const NAME_FIELD = "name";
export const DESCRIPTION_FIELD = "description";
export const ORBIT_LOCAL_ID_FIELD = "orbitLocalId";
export const PARAMETERS_FIELD = "parameters";
export const DEVICES_FIELD = "devices";
export const ENVIRONMENT_TYPE_FIELD = "type";

// INTERVALS
export const MSEC_1_HOUR     = 3600000;
export const MSEC_20_MINUTES = 1200000;
export const MSEC_10_MINUTES =  600000;
export const MSEC_10_SECONDS =   10000;

// DB ROLE NAMES
export const DB_ROLE_NAME_SUPERADMIN = 'admin';
export const DB_ROLE_NAME_WRITER = 'writer';

// ACCESS ROLES
export const ACCESS_ROLE_ADMIN = 'admin';
export const ACCESS_ROLE_EDITOR_TESTER = 'editor_tester'    // admin on license event page, catalog writer on catalog editing page
export const ACCESS_ROLE_SUPERADMIN = 'superadmin';

// REGEX PATTERNS

export const EMAIL_REGEX_STRING = "^[\\w.+-]+@[\\w.-]+\\.\\w\\w+$";
export const EMAIL_REGEX = /^[\w.+-]+@[\w.-]+\.\w\w+$/;

// OTHER CONSTANTS
export const INITIAL_DB_DEVICES = "initialDbDevices";
export const ARCHIVED = 'archived';
export const FULL_SCREEN = 'fullScreen';

export const QUERY_AS_USER_ID = 'queryAsUserId';
