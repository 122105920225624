import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'
import useDebug from 'hooks/useDebug'
import {Alert} from 'reactstrap'

import Tree from 'components/blocks/Tree'
import CheckBox from 'components/elements/CheckBox'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import withUriSearchParam from 'hocs/withUriSearchParam'
import withUriParams from 'hocs/withUriParams'
import CatalogSelectModal from 'appCatalog/modals/CatalogSelectModal'
import AdminEventView from './AdminEventView'
import LicenseScenarioView from './LicenseScenarioView'

import {queryLicenseEventsWithScenarios} from '../data/adminQueries'
import {createEvent, updateEvent, deleteEvent, cloneEvent, createScenario, updateScenario, deleteScenario, cloneScenario} from '../data/adminMutations'
import {addFromCatalog, addItemToCatalog} from 'appCatalog/data/catalogMutations'

import {breadcrumbsStore} from 'appBase/TopNav'
import {toggleSearchParam} from 'utils/urlUtils'
import {createMlJSONStringWithCurrentLanguage, parseBackendMessage} from 'utils/translationUtils'
import {ACCESS_ROLE_SUPERADMIN, ADMIN_EVENTS_VIEW, ARCHIVED, DB_ROLE_NAME_SUPERADMIN} from 'constants/constants'


const AdminEventsView = compose(
    withUriParams([
        ['mode', String, {optional: true, values: ['edit']}],
        ['eventId', Number],
        ['scenarioId', Number, {optional: true}]]),
    withUriSearchParam(ARCHIVED, 'showArchived'),
    queryLicenseEventsWithScenarios,
    addFromCatalog,
    addItemToCatalog,
    createEvent,
    updateEvent,
    deleteEvent,
    cloneEvent,
    createScenario,
    updateScenario,
    deleteScenario,
    cloneScenario
)((props) => {
    useDebug("AdminEventsView", props);

    const {mode, eventId, scenarioId, licenseId, licenseEventsWithScenarios, editConfirmationMessage,
        loading, errors, location, executeWithLock, executeWithConfirmation, user,
        // features:
        disabled, enableScenarioTreeEditButton, enableAddNewEvents, enableShowArchived,
        enableCatalogPrototypes, enableDeleteStudentSolutions, disableSolutions,
        enableBackLink, showBackLink} = props;
    const [error, setError] = useState(null);

    const isScenarioTreeEditDisabled = disabled || (enableScenarioTreeEditButton && mode !== 'edit');

    const navigate = useSmartNavigate();
    const {t, i18n} = useTranslation();

    const event = eventId && licenseEventsWithScenarios && licenseEventsWithScenarios.find(e => e.id === eventId);
    const scenario = event && scenarioId && event.scenarios.find(s => s.id === scenarioId);

    const [itemToUpdateFromCatalog, setItemToUpdateFromCatalog] = useState(null);
    const [itemToAddToCatalog, setItemToAddToCatalog] = useState(null);

    // link parts
    const modeUriPart = (mode && `/${mode}`) || '';
    const eventIdUriPart = (eventId && `/${eventId}`) || '';
    const scenarioIdUriPart = (scenarioId && `/${scenarioId}`) || '';
    const uriParamsUriPart = (props.uriParams && `/${props.uriParams}`) || '';

    // links
    const containerId = ADMIN_EVENTS_VIEW;
    breadcrumbsStore.register(containerId);

    const links = [{
        name: t('appAdmin.views.AdminEventsView.eventsAndScenarios'),
        to: `${props.uri}${modeUriPart}${props.location.search}`,
        back: enableBackLink && 1
    }];

    if (event) {
        links.push({name: `${event.name}`, to: `${props.uri}${modeUriPart}/${eventId}${props.location.search}`});
        if (scenario) {
            links.push({name: `${scenario.name}`, to: `${props.uri}${modeUriPart}/${eventId}/${scenarioId}/scenario${props.location.search}`});
        }
    }

    useEffect(() => {
        const isTerminalBreadcrumbs = !props.uriParams;
        breadcrumbsStore.set(containerId, links, isTerminalBreadcrumbs);
    });

    const navigateWithLock = (to) => executeWithLock(() => navigate(to));

    // object tree nodes
    const nodes = [];

    licenseEventsWithScenarios && licenseEventsWithScenarios.forEach(e => {
        const eventNode = {
            id: 'e' + e.id,
            parentId: null,
            name: e.isArchived ? t('appAdmin.views.AdminEventsView.archive') + e.name: e.name,
            event: e,
            items: [],
            actions: {
                "select": () => navigate(`${props.uri}${modeUriPart}/${e.id}${props.location.search}`),
                "moveTo": e.type === "course" && licenseId && user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN) && (() => setItemToAddToCatalog(e)),
                "clone": !isScenarioTreeEditDisabled && (() => props.cloneEvent(e.id)
                            .then(({data: {cloneEvent: {id, error}}}) => {
                                setError(error ? parseBackendMessage(error, t): null);
                                if (!error) {
                                    navigate(`${props.uri}${modeUriPart}/${id}${props.location.search}`);
                                }
                            })),
                "removeConfirmation": !isScenarioTreeEditDisabled && (() => props.deleteEvent(e.id))
            }
        };
        nodes.push(eventNode);


        // competition: all linear
        if (e.type === 'competition' && e.scenarios) {
            e.scenarios.forEach(s => {
                eventNode.items.push({
                    id: 's' + s.id,
                    name: s.name,
                    scenario: s,
                    actions: {
                        "select": () => navigate(`${props.uri}${modeUriPart}/${e.id}/${s.id}/scenario${props.location.search}`),
                        "moveTo": licenseId && user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN) && (() => setItemToAddToCatalog(s)),
                        "clone": !isScenarioTreeEditDisabled && (() => props.cloneScenario(s.id)
                                    .then(({data: {cloneScenario: {id, error}}}) => {
                                        setError(error ? parseBackendMessage(error, t): null);
                                        if (!error) {
                                            navigate(`${props.uri}${modeUriPart}/${e.id}/${id}/scenario${props.location.search}`);
                                        }
                                    })),
                        "removeConfirmation": !isScenarioTreeEditDisabled && (() => props.deleteScenario(s.id))
                    }
                });
            });

            !isScenarioTreeEditDisabled &&
            eventNode.items.push({
                id: 'addNewScenario',
                name: t('appAdmin.views.AdminEventsView.addNewScenario'),
                notSelectable: true,
                actions: {
                    "click": () => props.createScenario({
                        eventId: e.id,
                        nameMl: createMlJSONStringWithCurrentLanguage(t('appAdmin.views.AdminEventsView.newScenario'), i18n),
                        type: "none",
                        descriptionMl: createMlJSONStringWithCurrentLanguage("", i18n),
                    }).then(({data: {createScenario: {id, error}}}) => {
                        setError(error ? parseBackendMessage(error, t): null);
                        if (!error) {
                            navigate(`${props.uri}${modeUriPart}/${e.id}/${id}/scenario${props.location.search}`);
                        }
                    })
                }
            });

            !isScenarioTreeEditDisabled &&
            eventNode.items.push({
                id: 'addNewSection',
                name: t('appAdmin.views.AdminEventsView.addNewPage'),
                notSelectable: true,
                actions: {
                    "click": () => props.createScenario({
                        eventId: e.id,
                        nameMl: createMlJSONStringWithCurrentLanguage(t('appAdmin.views.AdminEventsView.newPage'), i18n),
                        type: "page",
                        descriptionMl: createMlJSONStringWithCurrentLanguage("", i18n),
                    }).then(({data: {createScenario: {id, error}}}) => {
                        setError(error ? parseBackendMessage(error, t): null);
                        if (!error) {
                            navigate(`${props.uri}${modeUriPart}/${e.id}/${id}/scenario${props.location.search}`);
                        }
                    })
                }
            });

            !isScenarioTreeEditDisabled &&
            eventNode.items.push({
                id: 'addFromCatalog',
                name: t('appAdmin.views.AdminEventsView.addScenarioFromCatalog'),
                notSelectable: true,
                actions: {
                    "click": () => setItemToUpdateFromCatalog(e)
                }
            });
        }

        // course: hierarchical:
        if (e.type === 'course' && e.scenarios) {
            const nodesForAfterprocessing = [];
            nodesForAfterprocessing.push(eventNode);
            e.scenarios.forEach(s => {
                const node = {
                    id: 's' + s.id,
                    parentId: s.parentId ? 's' + s.parentId : eventNode.id,
                    name: s.name,
                    scenario: s,
                    items: [],
                    actions: {
                        "select": () => navigate(`${props.uri}${modeUriPart}/${e.id}/${s.id}/scenario${props.location.search}`),
                        "moveTo": licenseId && user.roles.some(r => r.name === DB_ROLE_NAME_SUPERADMIN) && (() => setItemToAddToCatalog(s)),
                        "clone": !isScenarioTreeEditDisabled && (() => props.cloneScenario(s.id)
                                    .then(({data: {cloneScenario: {id, error}}}) => {
                                        setError(error ? parseBackendMessage(error, t): null);
                                        if (!error) {
                                            navigate(`${props.uri}${modeUriPart}/${e.id}/${id}/scenario${props.location.search}`);
                                        }
                                    })),
                        "removeConfirmation": !isScenarioTreeEditDisabled && (() => props.deleteScenario(s.id))
                    }
                };
                nodes.push(node);
                nodesForAfterprocessing.push(node);
            });

            !isScenarioTreeEditDisabled &&
            nodesForAfterprocessing.forEach(node => {
                node.items.push({
                    id: 'addNewTest',
                    name: t('appAdmin.views.AdminEventsView.addNewTest'),
                    notSelectable: true,
                    actions: {
                        "click": () => props.createScenario({
                            eventId: e.id,
                            parentId: node.scenario && node.scenario.id,
                            nameMl: createMlJSONStringWithCurrentLanguage(t('appAdmin.views.AdminEventsView.newTest'), i18n),
                            type: "test",
                            descriptionMl: createMlJSONStringWithCurrentLanguage("", i18n),
                        }).then(({data: {createScenario: {id, error}}}) => {
                            setError(error ? parseBackendMessage(error, t): null);
                            if (!error) {
                                navigate(`${props.uri}${modeUriPart}/${e.id}/${id}/scenario${props.location.search}`);
                            }
                        })
                    }
                });
                node.items.push({
                    id: 'addNewPage',
                    name: t('appAdmin.views.AdminEventsView.addNewPage'),
                    notSelectable: true,
                    actions: {
                        "click": () => props.createScenario({
                            eventId: e.id,
                            parentId: node.scenario && node.scenario.id,
                            nameMl: createMlJSONStringWithCurrentLanguage(t('appAdmin.views.AdminEventsView.newPage'), i18n),
                            type: "page",
                            descriptionMl: createMlJSONStringWithCurrentLanguage("", i18n),
                        }).then(({data: {createScenario: {id, error}}}) => {
                             setError(error ? parseBackendMessage(error, t): null);
                             if (!error) {
                                 navigate(`${props.uri}${modeUriPart}/${e.id}/${id}/scenario${props.location.search}`);
                             }
                        })
                    }
                });
                node.items.push({
                    id: 'addFromCatalog',
                    name: t('appAdmin.views.AdminEventsView.addScenarioFromCatalog'),
                    notSelectable: true,
                    actions: {
                        "click": () => setItemToUpdateFromCatalog(node.event || node.scenario)
                    }
                });
            });
        }
    });

    if (!isScenarioTreeEditDisabled && enableAddNewEvents) {
        nodes.push({
            id: 'addNewCompetitionEvent',
            parentId: null,
            name: t('appAdmin.views.AdminEventsView.createNewCompetition'),
            notSelectable: true,
            actions: {
                "click": () => props.createEvent({
                    nameMl: createMlJSONStringWithCurrentLanguage(t('appAdmin.views.AdminEventsView.newCompetition'), i18n),
                    type: "competition",
                    licenseId: props.licenseId
                }).then(({data: {createEvent: {id, error}}}) => {
                    setError(error ? parseBackendMessage(error, t) : null);
                    if (!error) {
                        navigate(`${props.uri}${modeUriPart}/${id}${props.location.search}`);
                    }
                })
            }
        });

        nodes.push({
            id: 'addNewCourseEvent',
            parentId: null,
            name: t('appAdmin.views.AdminEventsView.createNewCourse'),
            notSelectable: true,
            actions: {
                "click": () => props.createEvent({
                    nameMl: createMlJSONStringWithCurrentLanguage(t('appAdmin.views.AdminEventsView.newCourse'), i18n),
                    type: "course",
                    licenseId: props.licenseId
                }).then(({data: {createEvent: {id, error}}}) => {
                    setError(error ? parseBackendMessage(error, t) : null);
                    if (!error) {
                        navigate(`${props.uri}${modeUriPart}/${id}${props.location.search}`);
                    }
                })
            }
        });

        nodes.push({
            id: 'addFromCatalog',
            name: t('appAdmin.views.AdminEventsView.addCourseFromCatalog'),
            notSelectable: true,
            actions: {
                "click": () => setItemToUpdateFromCatalog({id: licenseId, __typename: 'License'})
            }
        });
    }


    // detail components, selectedId
    let detailsComponent, selectedId;
    if (event && scenario) {
            selectedId = 's' + scenarioId;
            detailsComponent =
                <LicenseScenarioView licenseId={props.licenseId}    // add images from license
                                     scenarioId={scenarioId}
                                     eventType={event.type}
                                     disabled={disabled}
                                     enableCatalogPrototypes={enableCatalogPrototypes}
                                     enableDeleteStudentSolutions={enableDeleteStudentSolutions}
                                     disableSolutions={disableSolutions}
                                     showBackLink={showBackLink}
                                     editConfirmationMessage={editConfirmationMessage}
                                     user={user}
                                     loading={loading} errors={errors} refetch={props.refetch}
                                     setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                     resetActionLock={props.resetActionLock}
                                     executeWithLock={props.executeWithLock}
                                     executeWithConfirmation={props.executeWithConfirmation}
                                     uri={`${props.uri}${modeUriPart}/${eventId}/${scenarioId}`}
                                     uriParams={props.uriParams} location={props.location} />
    } else if (event && !scenarioId) {
            selectedId = 'e' + eventId;
            detailsComponent =
                <AdminEventView licenseId={props.licenseId}  // for queryGroups
                                eventId={eventId}
                                disabled={disabled}
                                enableCatalogPrototypes={enableCatalogPrototypes}
                                enableDeleteStudentSolutions={enableDeleteStudentSolutions}
                                showBackLink={showBackLink}
                                editConfirmationMessage={editConfirmationMessage}
                                user={user}       // special access permissions if admin
                                loading={loading} errors={errors}
                                setActionLockWithMessageAndActions={props.setActionLockWithMessageAndActions}
                                resetActionLock={props.resetActionLock}
                                executeWithLock={props.executeWithLock}
                                executeWithConfirmation={props.executeWithConfirmation}
                                uri={`${props.uri}${modeUriPart}/${eventId}`}
                                uriParams={props.uriParams} location={props.location} />;
    }


    return (
        <div className="flex-grow d-flex flex-row scroll-parent">
            <div className="w-25 d-flex flex-column">
                <div className="scroll">
                    {enableShowArchived &&
                    <CheckBox checked={props.showArchived} label={t('appAdmin.views.AdminEventsView.showArchived')} className="ms-4 mt-3"
                              onChange={() => navigate(`${location.pathname}${toggleSearchParam(location.search, ARCHIVED)}`, {state: location.state})} />}
                    {enableScenarioTreeEditButton && !disabled &&    // special edit mode for catalog
                    <SaveButtonsBlock withEditMode
                                      onCancel={mode === 'edit' && (() => {
                                          navigate(`${props.uri}${eventIdUriPart}${scenarioIdUriPart}${uriParamsUriPart}${props.location.search}`);
                                      })}
                                      actions={[
                                          mode !== 'edit' && {
                                              name: t('appAdmin.views.AdminEventsView.edit'),
                                              allowOnErrors: true,
                                              action: () => {
                                                  const doNavigate = () => navigateWithLock(`${props.uri}/edit${eventIdUriPart}${scenarioIdUriPart}${uriParamsUriPart}${props.location.search}`);
                                                  editConfirmationMessage ? executeWithConfirmation(doNavigate, editConfirmationMessage) : doNavigate();
                                              }
                                          }
                                      ]}/>}
                    {/* TODO: Юра: сделать правильный драг&дроп для событий, только верхнеуровневая смена порядка */}
                    <Tree nodes={nodes} selectedId={selectedId} executeWithLock={props.executeWithLock}
                          dragAndDrop={!isScenarioTreeEditDisabled}
                          onDrop={(nodeToMove, newParent, afterNode) => {
                              if (nodeToMove.event) {
                                  props.updateEvent({
                                      id: nodeToMove.event.id,
                                      order: newParent && newParent.event ? (newParent.event.order + 1) : null
                                  }).then(({data: {updateEvent: {licenseEvent, error}}}) => {
                                      setError(error ? parseBackendMessage(error, t): null);
                                  });
                              }
                              else {
                                  props.updateScenario({
                                      id: nodeToMove.scenario.id,
                                      parentId: newParent && newParent.scenario && newParent.scenario.id,
                                      eventId: newParent && newParent.event && newParent.event.id,
                                      order: afterNode && afterNode.scenario ? (afterNode.scenario.order + 1) : null
                                  }).then(({data: {updateScenario: {licenseScenario, error}}}) => {
                                      setError(error ? parseBackendMessage(error, t): null);
                                  });
                              }
                          }}/>
                </div>
            </div>

            <div className="w-75 d-flex flex-column border border-dark border-top-0 border-end-0 border-bottom-0">
                {!detailsComponent && showBackLink &&
                <SaveButtonsBlock withBack
                                  executeWithLock={executeWithLock} />}

                {error &&
                <Alert className="mb-0" color="danger">{error}</Alert>}
                {detailsComponent}
            </div>

            {itemToUpdateFromCatalog &&
            <CatalogSelectModal show
                                selectScenario={itemToUpdateFromCatalog.__typename !== 'License'}
                                selectCourse={itemToUpdateFromCatalog.__typename === 'License'}
                                licenseId={licenseId}
                                addToType={itemToUpdateFromCatalog.__typename === 'License' ? 'license' :
                                        ['course', 'page'].includes(itemToUpdateFromCatalog.type) ? 'course' :
                                        'competition'}
                                onCancel={() => setItemToUpdateFromCatalog(null)}
                                onSelect={(catalogEntryVersionId) =>
                                    catalogEntryVersionId &&
                                        props.addFromCatalog(itemToUpdateFromCatalog.__typename, itemToUpdateFromCatalog.id, catalogEntryVersionId)
                                            .then(({data: {addFromCatalog: {eventId, scenarioId, error}}}) => {
                                                if (error) {
                                                    setError(error ? parseBackendMessage(error, t): null);
                                                    setItemToUpdateFromCatalog(null);
                                                } else {
                                                    setItemToUpdateFromCatalog(null);
                                                    eventId && navigate(`${props.uri}${modeUriPart}/${eventId}${props.location.search}`);
                                                    scenarioId && navigate(`${props.uri}${modeUriPart}${eventIdUriPart}/${scenarioId}/scenario${props.location.search}`)
                                                }
                                            })
                                }
                                loading={loading} errors={errors}
                                executeWithLock={props.executeWithLock}
                                uri={`${props.uri}${modeUriPart}${eventIdUriPart}${scenarioIdUriPart}`} uriParams={props.uriParams} location={props.location} />}

            {itemToAddToCatalog &&
            <CatalogSelectModal show
                                selectCatalog
                                queryAsRole={ACCESS_ROLE_SUPERADMIN}
                                itemToAddToCatalog={itemToAddToCatalog}
                                onCancel={() => setItemToAddToCatalog(null)}
                                onSelect={(catalogId) =>
                                    catalogId &&
                                        props.addItemToCatalog(itemToAddToCatalog.id, itemToAddToCatalog.__typename, catalogId)
                                            .then(({data: {addItemToCatalog: {id, error}}}) => {
                                                if (error) {
                                                    setError(error ? parseBackendMessage(error, t): null);
                                                    setItemToAddToCatalog(null);
                                                } else {
                                                    setItemToAddToCatalog(null);
                                                    navigate(`/catalog/${catalogId}/${id}/entry${props.location.search}`);
                                                }
                                            })
                                }
                                loading={loading} errors={errors}
                                executeWithLock={props.executeWithLock}
                                uri={`${props.uri}${modeUriPart}${eventIdUriPart}${scenarioIdUriPart}`} uriParams={props.uriParams} location={props.location} />}
        </div>);
});

export default AdminEventsView;