import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'
import useSmartNavigate from 'hooks/useSmartNavigate'
import useSimpleGeneratorDataSource from 'hooks/useSimpleGeneratorDataSource'
import useDebug from 'hooks/useDebug'
import classnames from 'classnames'

import Block from 'components/fieldBlocks/Block'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import withUriParams from 'hocs/withUriParams'
import Cesium3dView from './Cesium3dView'

import {queryResultsData} from '../../data/eventQueries'

import {prepareResultsData} from '../../data/eventObjects'
import {breadcrumbsStore} from 'appBase/TopNav'
import dateUtils from 'utils/dateUtils'
import {CESIUM_3D_VIEW, FULL_SCREEN, SOLUTION} from 'constants/constants'


const SimpleSolutionView = compose(
    withUriParams([
        ['itemType', String]]),
    queryResultsData
)(props => {
    useDebug("SimpleSolutionView", props);

    const {itemType, field, scenario, solution, showSolution, showResults, additionalSections} = props;

    // if not exists use default value
    const loading = props.loading || {resultsData: false};

    const navigate = useSmartNavigate();
    const {t, i18n} = useTranslation();

    // stable object to use as results dataSource
    const resultsData = useMemo(
        () => prepareResultsData(props.resultsData),
        [props.resultsData]);
    const resultsSource = useMemo(
        () => ({
            results: solution && solution.results,
            resultsData: (solution && solution.overrideResultsData) || resultsData || [],
            resultsData_loading: loading.resultsData
        }),
        [solution, resultsData, loading.resultsData]);
    const [resultsDataSource] = useSimpleGeneratorDataSource(resultsSource);

    // preparing links

    const containerId = SOLUTION;
    breadcrumbsStore.register(containerId);
    const breadcrumbs = [];
    breadcrumbs.push({
        name: (solution && solution.createdAt && dateUtils.dateToLocaleDateTimeString(solution.createdAt, i18n)) || t('appEvent.views.solutionViews.SimpleSolutionView.solution'),
        to: `${props.uri}`});

    useEffect(() => {
        const isTerminal = !itemType;
        breadcrumbsStore.set(containerId, breadcrumbs, isTerminal);
    });

    // subnavigation

    if (![null, CESIUM_3D_VIEW].includes(itemType)) { // item not found
        navigate(`${props.uri}${props.location.search}`);
        return null;
    }

    // rendering

    const {disabled, progress, dataSource, onChange, onValidate} = props;

    const solutionStatus = solution && solution.status;

    const sections = [];
    // if we need sections
    if (showSolution) {
        sections.push({className: "solution-tab-icon", name: t('appEvent.views.solutionViews.SimpleSolutionView.solution2'), title: t('appEvent.views.solutionViews.SimpleSolutionView.solution2'), key: "solution"});
    }
    // if we need sections and results are available
    if (showResults && solutionStatus !== "ready") {
        sections.push({className: "solution-result-tab-icon", name: t('appEvent.views.solutionViews.SimpleSolutionView.results'), title: t('appEvent.views.solutionViews.SimpleSolutionView.results'), key: "solution-result"});
        if (scenario.show3dResults) {
            sections.push({
                key: "3d", name: t('appEvent.views.solutionViews.SimpleSolutionView.3dTrajectory'), className: "tab-icon sim-3D-tab-icon",
                onClick: () => navigate(`${props.uri}/${CESIUM_3D_VIEW}${props.location.search}`, {state: {[FULL_SCREEN]: true}})
            });
        }
    }
    if (additionalSections) {
        additionalSections.map( s =>
            sections.push({className: s.className, name: s.name, title: s.title, key: s.key}));
    }

    // render

    return (
        <>
        {!itemType &&
        <Block sections={sections}>
            {showSolution &&
            <Block section="solution" className={classnames(!sections && "workpanel-gray")}>
                <GeneratorBlock disabled={disabled}
                                data={dataSource}
                                field={field}
                                items={scenario.prototypeParameters || []}
                                onChange={onChange}
                                onValidate={onValidate}/>
            </Block>}
            {additionalSections?.map(s =>
                <div key={s.key} section={s.key}>{s.component}</div>)}
            {showResults && solutionStatus !== "ready" &&
            <Block section="solution-result" className={classnames(!sections && "workpanel-gray")}>
                <p>{t('appEvent.views.solutionViews.SimpleSolutionView.solutionNumber')} {(solution && solution.number) || ""}:</p>
                {solutionStatus === "running" &&
                <p className="text-info">
                    {/* TODO: сделать: 1. менять текст только для generative_test, 2. заменить строки перевода на runningCalculation и runningScoring */}
                    {["test", "generative_test", "prog"].includes(scenario.type) ?
                        t('appEvent.views.solutionViews.SimpleSolutionView.running2'):
                        t('appEvent.views.solutionViews.SimpleSolutionView.running')}
                    {progress}
                </p>}
                {solutionStatus === "evaluating" &&
                <p className="text-info">{t('appEvent.views.solutionViews.SimpleSolutionView.evaluating')}</p>}
                {(solutionStatus === "evaluating" || solutionStatus === 'failed' || solutionStatus === 'finished') &&
                <GeneratorBlock data={resultsDataSource}
                                items={scenario.prototypeResults  || []} />}
            </Block>}
        </Block>}

        {itemType === CESIUM_3D_VIEW &&
        <Cesium3dView simulation={(solution && solution.simulation) || {}}
                      resultsData={resultsSource.resultsData}
                      skipSimulationDataCheck
                      loading={loading}
                      uri={`${props.uri}/${itemType}`} uriParams={props.uriParams} location={props.location} />}
        </>);
});

export default SimpleSolutionView;
